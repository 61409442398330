// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-storyblok-default-template-jsx": () => import("./../../../src/components/templates/StoryblokDefaultTemplate.jsx" /* webpackChunkName: "component---src-components-templates-storyblok-default-template-jsx" */),
  "component---src-components-templates-storyblok-event-archive-page-template-jsx": () => import("./../../../src/components/templates/StoryblokEventArchivePageTemplate.jsx" /* webpackChunkName: "component---src-components-templates-storyblok-event-archive-page-template-jsx" */),
  "component---src-components-templates-storyblok-hero-page-template-jsx": () => import("./../../../src/components/templates/StoryblokHeroPageTemplate.jsx" /* webpackChunkName: "component---src-components-templates-storyblok-hero-page-template-jsx" */),
  "component---src-components-templates-storyblok-start-page-template-jsx": () => import("./../../../src/components/templates/StoryblokStartPageTemplate.jsx" /* webpackChunkName: "component---src-components-templates-storyblok-start-page-template-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archiv-jsx": () => import("./../../../src/pages/archiv.jsx" /* webpackChunkName: "component---src-pages-archiv-jsx" */),
  "component---src-pages-demo-jsx": () => import("./../../../src/pages/demo.jsx" /* webpackChunkName: "component---src-pages-demo-jsx" */),
  "component---src-pages-event-jsx": () => import("./../../../src/pages/event.jsx" /* webpackChunkName: "component---src-pages-event-jsx" */),
  "component---src-pages-location-1-jsx": () => import("./../../../src/pages/location1.jsx" /* webpackChunkName: "component---src-pages-location-1-jsx" */),
  "component---src-pages-location-2-jsx": () => import("./../../../src/pages/location2.jsx" /* webpackChunkName: "component---src-pages-location-2-jsx" */),
  "component---src-pages-schedule-burgfestspiele-jsx": () => import("./../../../src/pages/schedule-burgfestspiele.jsx" /* webpackChunkName: "component---src-pages-schedule-burgfestspiele-jsx" */),
  "component---src-pages-schedule-jsx": () => import("./../../../src/pages/schedule.jsx" /* webpackChunkName: "component---src-pages-schedule-jsx" */),
  "component---src-pages-start-jsx": () => import("./../../../src/pages/start.jsx" /* webpackChunkName: "component---src-pages-start-jsx" */),
  "component---src-pages-storyblok-preview-js": () => import("./../../../src/pages/storyblok-preview.js" /* webpackChunkName: "component---src-pages-storyblok-preview-js" */)
}

